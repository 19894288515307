export const inputValidationMixin = {
  props: {
    lazyValidator: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      validMessage: null,
      invalidMessage: null,
    };
  },
  watch: {
    value(val) {
      if (!this.lazyValidator) return this.validate(val);
    }
  },
  methods: {
    validate(val = false, methods = {}) {
      val = val || this.value;
      if (this.validator && typeof this.validator === "object") {
        for (let v of this.validator) {
          let key = this.id || this.uid + this._uid;
          let messObject = {
            key,
            message: "",
            label: this.label || ""
          };
          if (
            typeof v.validator === "function"
              ? v.validator(val)
              : v.validator.test(val)
          ) {
            messObject.message = this.validMessage = v.validMessage;
            this.invalidMessage = null;
            this.validState = true;
            if (v.valid) v.valid(messObject);
            if (methods.valid) methods.valid(messObject);
            if (v.state) v.state(true);
          } else {
            messObject.message = this.invalidMessage = v.invalidMessage;
            if (v.invalid) v.invalid(messObject);
            if (methods.invalid) methods.invalid(messObject);
            if (v.state) v.state(false);
            break;
          }
        }
      }
    }
  }
};
