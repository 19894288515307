<template>
  <div class="form-group">
    <label
      :class="[required ? 'required' : false]"
      v-if="label"
      :for="id || uid + _uid"
    >
      {{ label }}
    </label>
    <div class="d-flex align-items-stretch input-wrap">
      <div v-if="$slots['append-content']" class="append-content">
        <slot name="append-content" />
      </div>
      <input
        :required="required"
        v-bind="$attrs"
        v-on="listeners"
        :type="type"
        class="form-control"
        :class="_class"
        :value="value"
        :id="id || uid + _uid"
        @input="onInput($event)"
        @change="onChange($event)"
      />
    </div>
    <transition name="fade">
      <small
        v-if="invalidMessage || validMessage || invalidFeedback || description"
        :id="'area-' + id"
        class="form-text text-muted"
        :class="[invalidFeedback || invalidMessage ? 'danger-color' : false]"
      >
        {{ invalidMessage || validMessage || invalidFeedback || description }}
      </small>
    </transition>
  </div>
</template>

<script>
import { inputProps as props } from "./input-props";
import { inputValidationMixin } from "@/components/input-mixins";

export default {
  name: "AInput",
  mixins: [inputValidationMixin],
  props: {
    ...props,
    label: String,
    _class: String,
    id: String,
    type: {
      type: String,
      default: "text"
    },
    description: String
  },

  computed: {
    listeners() {
      const { input, change, ...listeners } = this.$listeners; // eslint-disable-line no-unused-vars
      return listeners;
    }
  },
  methods: {
    onInput(e) {
      this.state = e.target.value;
      this.$emit("input", this.state, e);
      if (this.lazy === true) {
        return;
      }
      clearTimeout(this.syncTimeout);
      this.syncTimeout = setTimeout(
        () => {
          this.$emit("update:value", this.state, e);
        },
        this.lazy !== false ? this.lazy : 0
      );
    },
    onChange(e) {
      this.state = e.target.value;
      this.$emit("change", this.state, e);
      this.$emit("update:value", this.state, e);
    }
  }
};
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: var(--th-inputs-margin-buttom);

  .input-wrap {
    border: 4px solid rgba(135, 149, 168, 10%);
    border-radius: 10px;
    min-height: 48px;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    .append-content{
      border-right: 4px solid rgba(135, 149, 168, 10%);
    }
    input{
      padding: 9px 11px;
      border: none;
      &::placeholder {
        color: rgba(143, 142, 148, 1);
      }
    }
  }
}
</style>
